import React from 'react';
import styled from 'styled-components';
import {Tag} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';

function SessionItem(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const {product} = props;

  const past =
    product.session && product.session.start_time
      ? new Date(
          `${product.session.date} ${product.session.start_time}`,
        ).getTime() <= new Date().getTime()
      : true;

  return (
    <Wrapper
      disabled={past}
      mobile={mobile}
      onClick={() => {
        if (!past) {
          AppActions.navigate(
            !product.type || product.type === 'default'
              ? `/product/?id=${product.id}`
              : `/product/?id=${product.group_root_product}&child=${product.id}`,
          );
        }
      }}>
      <div className="session-img">
        <img
          src={product.images[0]?.expected_url || '/images/empty-img.png'}
          alt="session"
        />
      </div>
      <div className="info">
        <h3>{product.name}</h3>
        <div className="session">
          <div className="time">
            {product.session.date} {product.session.start_time} -
            {product.session.end_time}
          </div>
          <p>{product.session.note}</p>
        </div>
        {past && (
          <Tag color="volcano" style={{marginBottom: 10}}>
            已結束
          </Tag>
        )}
        <div className="price">
          {!!product.price && (
            <span className="curr-price">${product.price}</span>
          )}

          {!!product.original_price && (
            <span className="original-price">${product.original_price}</span>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 20px;

  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  & > .session-img > img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  & > .info {
    padding: 30px;

    & > .session {
      & > .time {
        margin-bottom: 5px;
      }

      color: #495057;
    }

    & > .price {
      & > .original-price {
        text-decoration: line-through;
        color: #cbcbcb;
        margin-left: 10px;
      }

      & > .curr-price {
        font-weight: bold;
        font-size: 1.4rem;
        color: var(--primaryColor);
      }
    }
  }
`;

export default SessionItem;
