import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import Calendar from 'rev.sdk.js/Components/Calendar';
import {DT} from 'rev.sdk.js/Components/Calendar/CalendarUtil';
import {useOutlet} from 'reconnect.js';
import {
  formatYearMonthStr,
  formatDateStr,
  prependTwoZero,
} from '../../Utils/TimeUtil';
import * as AppActions from '../../AppActions';
import withPageEntry from '../../withPageEntry';
import SessionItem from './SessionItem';

function DateCell(props) {
  const {year, month, date, products, classNames: _classNames, onClick} = props;
  const curr = DT({year, month, date});

  let availSessions = [];
  let classNames = [];

  for (const product of products) {
    const [y, m, d] = product.session.date.split('-');
    if (
      curr.getFullYear() === parseInt(y) &&
      curr.getMonth() + 1 === parseInt(m) &&
      curr.getDate() === parseInt(d)
    ) {
      availSessions.push(product);
    }
  }

  if (availSessions.length === 0) {
    classNames.push('disabled');
  }

  return (
    <DateCellWrapper
      className={_classNames.concat(classNames).join(' ')}
      onClick={(e) => {
        onClick(e);
      }}>
      {date}
      {availSessions.length > 0 && (
        <div style={{fontSize: 4}}>{availSessions.length}場</div>
      )}
    </DateCellWrapper>
  );
}

const DateCellWrapper = styled.div`
  position: relative;

  /* here to custom*/
  &.disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.r-cell.r-date.r-today {
    border: solid 1px var(--primaryColor);
  }

  &.r-today::after {
    content: '';
  }

  &.r-cell.r-date.r-today.r-selected {
    font-wieght: 600;
    background-color: #ffffff;
  }

  &.r-cell.r-date.r-selected {
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
  }

  &.r-cell.r-date:not(.disabled, .past):hover {
    border: none;
    background-color: var(--primaryColor);
  }
`;

function SessionList(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  const [selectedDt, setSelectedDt] = React.useState(null);
  const [products, setProducts] = React.useState([]);

  const fetchSessionsByMonth = React.useCallback(async (yearMontString) => {
    AppActions.setLoading(true);
    try {
      setProducts(await AppActions.fetchSessions(yearMontString));
      setSelectedDt(null);
    } catch (ex) {
      console.warn('Session ListPage.fetchSessionsByMonth ex', ex);
    }
    AppActions.setLoading(false);
  }, []);

  React.useEffect(() => {
    fetchSessionsByMonth(formatYearMonthStr(new Date()));
  }, [fetchSessionsByMonth]);

  const filteredProducts = products.filter((p) => {
    if (selectedDt) {
      return p.session.date === formatDateStr(selectedDt);
    }

    return true;
  });

  return (
    <Wrapper mobile={mobile}>
      <div className="side">
        <Calendar
          onSelect={({year, month, date, dt}) => {
            setSelectedDt(
              selectedDt
                ? selectedDt.getTime() === dt.getTime()
                  ? null
                  : dt
                : dt,
            );
          }}
          onClickPrev={({year, month}) => {
            fetchSessionsByMonth(`${year}-${prependTwoZero(month + 1)}`);
          }}
          onClickNext={({year, month}) => {
            fetchSessionsByMonth(`${year}-${prependTwoZero(month + 1)}`);
          }}
          renderDate={(props) => {
            return <DateCell key={props.date} {...props} products={products} />;
          }}
        />
      </div>
      <div className="gutter" />
      <div className="container">
        <div style={{margin: '10px 0px'}}>請選擇有場次的日期</div>
        {filteredProducts.map((product) => (
          <SessionItem product={product} />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  aligh-items: center;

  ${(props) =>
    props.mobile
      ? `
        flex-direction: column;
        padding: 84px 20px 20px 20px;
        flex-wrap: wrap;
        `
      : `
        flex-direction: row;
        padding: 84px 40px 40px;
        `};

  & > div.side {
    width: 100%;
    padding: 10px;

    ${(props) =>
      props.mobile
        ? `
          max-width: 300px;
          height: auto;
          position: static;
          margin: 0 auto;
          `
        : `
          max-width: 260px;
          height: 100%;
          position: sticky;
          top: 84px;
          left: 0px;
         `};

    & .r-inner > .r-row.r-head {
      & > .r-cell.r-title {
        font-weight: 500;
      }

      & > .r-cell.r-next:hover,
      .r-cell.r-prev:hover {
        background-color: var(--primaryColor);
      }
    }
  }

  & > div.gutter {
    width: ${(props) => (props.mobile ? '0px' : '20px')};
    height: ${(props) => (props.mobile ? '20px' : '0px')};
  }

  & > div.container {
    flex-grow: 1;
  }
`;

export default withPageEntry(SessionList);
